import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

// import Modal from 'react-modal'

// const [modalIsOpen, setModalIsOpen] = useState(false);

const IndexPage = ({ data }) => (




  <Layout>
    <SEO title="Home" />
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}

    {data.allMarkdownRemark.edges.map(post => (

      <div style={{ maxWidth: `800px`, margin: `0 auto` }}>
        <Link key={post.node.id} to={post.node.frontmatter.path} style={{ textDecoration: `none` }}>
          {/* <img src={post.node.frontmatter.image} style={{ width: `100%` }} alt={post.node.frontmatter.imagealt} /> */}
          < Image />

        </Link>
        <p>{post.node.frontmatter.date}</p>
        <h2 style={{ color: `rgba(0,0,0,0.87)` }}>{post.node.frontmatter.title}</h2>
        <p>{post.node.frontmatter.description}</p>
        <Link key={post.node.id} to={post.node.frontmatter.path}>
          <p style={{ float: `right`, backgroundColor: `#017681`, padding: `8px`, borderRadius: `10px`, color: `#ffffff` }}>Read More</p>
        </Link>
      </div>



    ))}
    {/* <Link to="/page-2/">Go to page 2</Link> */}


    {/* <button onClick={() => setModalIsOpen(true)} > Open Modal</button>
    <Modal isOpen={modalIsOpen} >
      <h2>Modal Title</h2>
      <p>Modal Body</p>
    </Modal> */}

  </Layout >
)

export const pageQuery = graphql`
  query IndexQuery {
      allMarkdownRemark(limit: 10) {
    edges {
      node {
        id
        frontmatter {
          title
          path
          image
          description
          imagealt
          date
        }
      }
    }
  }
  }
`

export default IndexPage
